<template>
  <ib-free-trial-service-provider v-slot="{freeTrial, isDangerBtn, goToPricingPage}">
    <div
      v-if="freeTrial"
      :class="{'is-danger-btn': isDangerBtn}"
      class="ib-free-trial-banner-container d-flex flex-column flex-md-row justify-content-center align-items-center position-relative text-center p-2"
    >
      <h5
        class="text m-0"
      >
        {{ $t('freeTrial.freeTrialWillExpire') }} <span v-text="freeTrial" />
      </h5>
      <div
        :class="{'is-danger-btn': isDangerBtn}"
        class="upgrade-button cursor-pointer text-white font-outfit-regular mt-2 mt-md-0 mb-1 mb-md-0"
        @click="goToPricingPage"
      >
        <i class="ib-icon ib-icon-rocket upgrade-icon" />
        {{ $t('upgrade') }}
      </div>
    </div>
  </ib-free-trial-service-provider>
</template>

<script>
import IbFreeTrialServiceProvider from '@/components/_v2/FreeTrial/IbFreeTrialServiceProvider'

export default {
  name: 'IbFreeTrialBanner',

  components: {
    IbFreeTrialServiceProvider
  }
}
</script>

<style scoped lang="scss">
.ib-free-trial-banner-container {
  background-color: #FFF5D9;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 14%), 6px 0 6px 0 rgb(0 0 0 / 6%);
  z-index: 2000;

  &.is-danger-btn {
    background-color: #FFEBEA;
  }

  .upgrade-button {
    background: $color-yellow;
    color: $color-white;
    border-radius: 5px;
    margin-left: 15px;
    padding: 3px 12px;
    transition: all 0.25s;

    &:hover {
      opacity: .7;
    }

    &.is-danger-btn {
      background-color: $color-red;
    }

    .upgrade-icon {
      color: $color-white;
      font-size: 16px;
      margin-right: 3px;
    }
  }
}
</style>
