<template>
  <!-- Mobile Top bar -->
  <div class="mobile-top-bar d-flex flex-grow-1 align-items-center">
    <!-- Left menu button -->
    <div class="position-relative" @click="onClickIdeaAvatar()">
      <ib-idea-avatar :idea="idea" size="md" />
      <sub class="avatar-sub el-icon-s-unfold" />
    </div>
    <!-- /Left menu button -->

    <div
      class="top-bar-content d-flex flex-column flex-grow-1 mx-2 cursor-pointer"
      @click="toggleDropdown"
    >
      <!-- Page Title -->
      <span
        :class="{'text-uppercase': isDropdownShow}"
        class="page-title three-dots"
      >
        {{ title }}
        <i v-if="isDropdownShow" class="ib-icon-sort-down" />
      </span>
      <!-- /Page Title -->

      <!-- Page Subtitle -->
      <span
        v-if="$route.meta.subtitle"
        class="page-subtitle d-flex align-items-center three-dots"
      >
        {{ $t($route.meta.subtitle) }}
        <svg xmlns="http://www.w3.org/2000/svg" width="45.701" height="45.7" viewBox="0 0 45.701 45.7" fill="#65B265" v-if="isStepCompleted" v-bind:svg-inline="''" v-bind:class="'ml-1 complete-step-check'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.687 38.332a5.308 5.308 0 01-7.505 0L1.554 26.704A5.306 5.306 0 119.059 19.2l6.928 6.927a1.344 1.344 0 001.896 0L36.642 7.368a5.308 5.308 0 017.505 7.504l-23.46 23.46z"/></svg>
      </span>
      <!-- /Page Subtitle -->
    </div>

    <template v-if="isExportAndShareVisible">
      <div class="steps-navigation-buttons-wrapper">
        <financial-plan-export />
        <financial-plan-share />
      </div>
    </template>

    <!-- Right Menu Button -->
    <ib-button class="hidden-md-and-up py-1 px-2" icon="ib-icon-tune" size="lg" @click="onClickAccountSettings" />
    <!-- /Right Menu Button -->

    <!-- Dropdown -->
    <component :is="dropdownComponent" ref="mobileTopBarDropdown" />
    <!-- /Dropdown -->
  </div>
  <!-- /Mobile Top bar -->
</template>

<script>
import BusinessGuideMobileDropdown from '@/views/Home/Components/MobileTopBar/BusinessGuideMobileDropdown'
import FinancialPlanMobileDropdown from '@/views/Home/Components/MobileTopBar/FinancialPlanMobileDropdown'

import { mapGetters, mapMutations, mapState } from 'vuex'
import FinancialPlanExport from '@/views/Home/StoryMode/FinancialPlan/Components/FinancialPlanExport.vue'
import FinancialPlanShare from '@/views/Home/StoryMode/FinancialPlan/Components/FinancialPlanShare.vue'

export default {
  name: 'MobileTopBar',

  components: {
    FinancialPlanShare,
    FinancialPlanExport,
    BusinessGuideMobileDropdown,
    FinancialPlanMobileDropdown
  },

  data () {
    return {
      dropdownOpen: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', [
      'getStepIsCompleted'
    ]),

    dropdownComponent () {
      return this.isBusinessGuide ? 'BusinessGuideMobileDropdown' : 'FinancialPlanMobileDropdown'
    },

    isStepCompleted () {
      return this.getStepIsCompleted(this.$route.meta.completedStepName)
    },

    isBusinessGuide () {
      return this.$route.name !== 'business-guide' && this.$route.name.startsWith('business-guide')
    },

    isFinancialPlan () {
      return this.$route.name.startsWith('financial-plan')
    },

    title () {
      if (this.isBusinessGuide) return this.$t('sidebar.businessGuide')
      if (this.isFinancialPlan) return this.$t('sidebar.financialPlan')

      return this.$route.meta.title
    },

    isExportAndShareVisible () {
      return this.isFinancialPlan
    },

    isDropdownShow () {
      return this.isBusinessGuide || this.isFinancialPlan
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setSidebarToggle',
      'setAccountSettingsToggle'
    ]),

    toggleDropdown () {
      if (!this.isDropdownShow) return
      this.$refs.mobileTopBarDropdown.$refs.mobileTopBarDropdown.toggle()
    },

    closeDropdown () {
      this.$refs.mobileTopBarDropdown.$refs.mobileTopBarDropdown.close()
    },

    onClickIdeaAvatar () {
      this.closeDropdown()
      this.setSidebarToggle()
    },

    onClickAccountSettings () {
      this.closeDropdown()
      this.setAccountSettingsToggle()
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-top-bar {
  overflow: hidden;
  padding: 0 10px;
}

.top-bar-content {
  white-space: nowrap;
  overflow: hidden;
}

.page-title {
  font-size: 18px;
}

.page-subtitle {
  font-size: 15px;
}

.complete-step-check {
  width: 15px;
  height: 15px;
}

.avatar-sub {
  position: absolute;
  right: -3px;
  bottom: -1px;
  background-color: $color-primary;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 3px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}

.steps-navigation-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  margin-right: 10px;
}
</style>
