<template>
  <ib-dialog
    id="dialog-payment-history"
    :visible="dialogPaymentHistoryVisible"
    fullscreen
    empty-header
    :closable="false"
    @open="onOpenDialog"
    @close="closeDialog"
  >
    <template #title>
      <ib-dialog-container max-width="990px">
        <div class="d-flex justify-content-end">
          <ib-button
            outline
            variant="black"
            size="sm"
            font-family="medium"
            @click="closeDialog"
          >
            {{ $t('back') }}
          </ib-button>
        </div>
      </ib-dialog-container>
    </template>

    <ib-dialog-container max-width="990px">
      <loader v-if="paymentHistoryData === null" />

      <template v-else>
        <!-- Dialog Current Subscription -->
        <template v-if="paymentSlug !== ''">
          <div class="current-subscription text-center">
            <h1>{{ $t('dialogs.paymentHistory.title') }}</h1>
            <h4 class="m-0">
              {{ paymentSlug }} / {{ paymentIdeas }} {{ paymentIdeas > 1 ? 'ideas' : 'idea' }} / {{ paymentType }}  ({{ paymentCurrency }} {{ paymentOriginalPrice }} + {{ $tc('tax') }})
            </h4>
            <p v-if="paymentNextBillDate" class="m-0">
              {{ $t('dialogs.paymentHistory.autoRenewal') }}  {{ paymentNextBillDate }}
            </p>
          </div>
        </template>
        <!-- /Dialog Current Subscription -->

        <ib-divider block class="my-3" />

        <!-- Dialog Body -->
        <div>
          <h1 class="text-center m-0 mb-4">
            {{ $t('dialogs.paymentHistory.paymentHistory') }}
          </h1>

          <el-table
            :data="paymentHistoryData"
            stripe
            height="280"
          >
            <el-table-column
              prop="created_at"
              :label="`${$t('dialogs.paymentHistory.tableColumns.date')}`"
              align="center"
              :formatter="dateFormatter"
            />
            <el-table-column
              prop="customer_name"
              align="center"
              :label="`${$t('dialogs.paymentHistory.tableColumns.profileOwner')}`"
            />
            <el-table-column
              :label="`${$t('dialogs.paymentHistory.tableColumns.pricePlan')}`"
              align="center"
              width="200"
              :formatter="formatter"
            />
            <el-table-column
              prop="currency"
              :label="`${$t('dialogs.paymentHistory.tableColumns.currency')}`"
              align="center"
              width="90"
            />
            <!--<el-table-column
                prop="payment_tax"
                label="VAT"
                align="center"
              >
              </el-table-column>-->
            <el-table-column
              prop="sale_gross"
              :label="`${$t('dialogs.paymentHistory.tableColumns.totalAmount')}`"
              width="130"
              align="center"
            />
            <el-table-column
              prop="receipt_url"
              :label="`${$t('dialogs.paymentHistory.tableColumns.invoice')}`"
              width="130"
              align="center"
            >
              <template slot-scope="scope">
                <a :href="scope.row.receipt_url" target="_blank">{{ $t('dialogs.paymentHistory.download') }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- /Dialog Body -->
      </template>
    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import Loader from '@/components/Loader'
import MixinCurrencies from '@/mixins/currencies'
import paymentTypes from '@/data/paymentTypes.json'

export default {
  name: 'DialogPaymentHistory',

  components: {
    Loader
  },

  mixins: [
    MixinCurrencies
  ],

  props: {
    dialogPaymentHistoryVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      paymentHistory: [],
      paymentHistoryData: null,
      paymentSlug: '',
      paymentType: '',
      paymentIdeas: 0,
      paymentCurrencySymbol: '',
      paymentOriginalPrice: 0,
      paymentCurrency: null,
      paymentNextBillDate: ''
    }
  },

  created () {
    this.loadPaymentData()
  },

  methods: {
    dateFormatter (row, column, cellValue) {
      return cellValue.slice(0, 10)
    },

    onOpenDialog () {
      this.loadPaymentHistoryData()
    },

    closeDialog () {
      this.$emit('close-dialog')
    },

    formatter (row) {
      const planSlug = row.slug
      const planType = paymentTypes[row.type]
      const planIdeas = row.ideas > 1 ? row.ideas + ' ideas' : row.ideas + ' idea'

      return planSlug + ' / ' + planIdeas + ' / ' + planType
    },

    currentSubscribed () {
      if (this.paymentHistory && this.paymentHistory.length) {
        const payment = this.paymentHistory[0]

        this.paymentSlug = payment.slug
        this.paymentType = paymentTypes[payment.type]
        this.paymentIdeas = payment.ideas
        this.paymentOriginalPrice = payment.total_price
        this.paymentCurrency = payment.currency
      }

      this.currencies.forEach((currency) => {
        if (currency.code === this.$store.state.idea.ideaSettings.currency) {
          this.paymentCurrencySymbol = currency.symbol
        }
      })

      if (this.$store.state.user.subscription.nextBillDate !== undefined && this.$store.state.user.subscription.nextBillDate !== null) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const today = new Date(this.$store.state.user.subscription.nextBillDate.date)

        this.paymentNextBillDate = today.toLocaleDateString('en-US', options)
      }
    },

    async loadPaymentData () {
      const response = await this.$http.get('payment')
      this.paymentHistory = response.data.payload
      this.currentSubscribed()
    },

    loadPaymentHistoryData () {
      this.$http.get('payment')
        .then(response => {
          this.paymentHistoryData = response.data.payload
        }).catch(() => {
          this.setDialogPaymentHistoryState(false)
          this.$notify({
            title: 'Error',
            dangerouslyUseHTMLString: true,
            message: 'Something went wrong <br>Please try again latter!',
            type: 'error'
          })
        })
    }
  }
}
</script>
