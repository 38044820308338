import { render, staticRenderFns } from "./IbFreeTrialBanner.vue?vue&type=template&id=29c6ff35&scoped=true"
import script from "./IbFreeTrialBanner.vue?vue&type=script&lang=js"
export * from "./IbFreeTrialBanner.vue?vue&type=script&lang=js"
import style0 from "./IbFreeTrialBanner.vue?vue&type=style&index=0&id=29c6ff35&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c6ff35",
  null
  
)

export default component.exports