<template>
  <ib-drawer
    ref="accountSettings"
    :visible="visible"
    @open="onOpen"
    @close="$emit('close')"
  >
    <!-- Header -->
    <account-settings-header />
    <!-- /Header -->

    <!-- Main menu -->
    <account-settings-menu @close="closeDrawer" />
    <!-- /Main menu -->

    <!-- Divider -->
    <div>
      <ib-divider class="d-flex" block />
    </div>
    <!-- /Divider -->

    <!-- Additional menu -->
    <account-settings-additional-menu @close="closeDrawer" />
    <!-- /Additional menu -->
  </ib-drawer>
</template>

<script>
import AccountSettingsAdditionalMenu
  from '@/views/Home/Components/RightSidebar/components/AccountSettingsAdditionalMenu'
import AccountSettingsHeader from '@/views/Home/Components/RightSidebar/components/AccountSettingsHeader'
import AccountSettingsMenu from '@/views/Home/Components/RightSidebar/components/AccountSettingsMenu'
import IbDrawer from '@/components/_v2/Drawer/IbDrawer'

import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AccountSettings',

  components: {
    AccountSettingsHeader,
    AccountSettingsMenu,
    AccountSettingsAdditionalMenu,
    IbDrawer
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions('user', [
      'getMyProfile'
    ]),

    ...mapMutations('ui', [
      'setDialogPaymentHistoryState',
      'setDialogUserInviteState',
      'setDialogLogoutState'
    ]),

    onOpen () {
      if (!this.$store.state.user.subscription.update_url) {
        this.getMyProfile()
      }
    },

    closeDrawer () {
      this.$refs.accountSettings.$refs.ibDrawer.closeDrawer()
    }
  }
}
</script>
