import { render, staticRenderFns } from "./AccountSettingsMenu.vue?vue&type=template&id=710de907&scoped=true"
import script from "./AccountSettingsMenu.vue?vue&type=script&lang=js"
export * from "./AccountSettingsMenu.vue?vue&type=script&lang=js"
import style0 from "./AccountSettingsMenu.vue?vue&type=style&index=0&id=710de907&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710de907",
  null
  
)

export default component.exports