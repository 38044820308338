<template>
  <ib-dialog
    :visible="dialogUpgradeVisible"
    width="530px"
    :closable="false"
    :append-to-body="true"
    empty-header
    @close="closeDialog"
  >
    <div class="text-center">
      <div class="illustration-wrapper">
        <img src="@/assets/img/illustrations/dialogs/upgrade.svg" alt="">
      </div>
      <h1>{{ $t('dialogs.upgrade.title') }}</h1>
      <p class="text-lead">
        {{ $t('dialogs.upgrade.description') }}
      </p>
    </div>
    <template #footer>
      <ib-dialog-footer
        button-size="md"
        :cancel-btn-text="$t('dialogs.upgrade.imNotReady')"
        :submit-btn-text="$t('dialogs.upgrade.goToPricingPage')"
        space-between-buttons
        @submit="onSubmit"
        @cancel="closeDialog"
      />
    </template>
  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogUpgrade',

  props: {
    dialogUpgradeVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    onSubmit () {
      this.$router.push({ name: 'payment' })
      this.closeDialog()
    },

    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.text-lead {
  font-size: 1.5rem;
  opacity: 0.5;
}
</style>
