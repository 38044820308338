<template>
  <div class="menu-wrapper">
    <!-- Menu -->
    <el-menu
      id="menu-sidebar"
      ref="menu"
      :router="true"
      :unique-opened="true"
      class="el-menu-vertical-demo"
      :default-active="defaultActive"
    >
      <!-- Home -->
      <router-link class="decoration-none" :to="{name: 'home'}">
        <el-menu-item
          class="border-bottom item-big"
          :class="{
            'active is-active': isActive('home')
          }"
        >
          <i class="icon ib-icon ib-icon-home h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-home-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.home') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Home -->

      <!-- Business Guide -->
      <router-link class="decoration-none" :to="{name: 'business-guide'}">
        <el-menu-item
          :class="{
            'active is-active': isActive('business-guide')
          }"
        >
          <i class="icon ib-icon ib-icon-user-manual h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-user-manual-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.businessGuide') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Business Guide -->

      <!-- Financials -->
      <router-link class="decoration-none" :to="{name: 'financial-plan-overview'}">
        <el-menu-item
          :class="{
            'active is-active': isActive('financial-plan')
          }"
        >
          <i class="icon ib-icon ib-icon-bar-chart h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-bar-chart-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.financialPlan') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Financials -->

      <!-- Business Plan -->
      <router-link class="decoration-none" :to="{name: 'business-plan'}">
        <el-menu-item
          :class="{
            'active is-active': isActive('business-plan')
          }"
        >
          <i class="icon ib-icon ib-icon-spiral-bound-booklet h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-spiral-bound-booklet-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.businessPlan') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Business Plan -->

      <!-- Whiteboard -->
      <router-link class="decoration-none" :to="{name: 'whiteboard'}">
        <el-menu-item
          :class="{
            'active is-active': isActive('whiteboard')
          }"
        >
          <i class="icon ib-icon ib-icon-whiteboard h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-whiteboard-fill h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">
            {{ $t('sidebar.whiteboard') }}
            <!--            <ib-sup v-if="!isMenuCollapsed" variant="yellow" class="ml-1">-->
            <!--              2.0-->
            <!--            </ib-sup>-->
          </span>
        </el-menu-item>
      </router-link>
      <!-- /Whiteboard -->

      <!-- Idea Plan -->
      <router-link class="decoration-none" :to="{name: 'idea-plan'}">
        <el-menu-item
          :class="{
            'active is-active': isActive('idea-plan')
          }"
        >
          <i class="icon ib-icon ib-icon-business-model-canvas h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-business-model-canvas-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.ideaPlan') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Idea Plan -->

      <!-- Validation -->
      <router-link class="decoration-none" :to="{name: 'validation-idea-score'}">
        <el-menu-item
          :class="{
            'active ': isActive('validation-idea-score')
          }"
        >
          <i class="icon ib-icon ib-icon-validate-light h4 mr-3 icon-validation-custom" />
          <i class="icon-filled ib-icon ib-icon-validate-filled h4 mr-3 icon-validation-custom" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.validation') }} </span>
        </el-menu-item>
      </router-link>
      <!-- /Validation -->

      <!-- Collection -->
      <router-link class="decoration-none" :to="{name: 'idea-collection'}">
        <el-menu-item
          class="collection item-big"
          :class="{
            'active is-active': isActive('idea-collection')
          }"
        >
          <i class="icon ib-icon ib-icon-book-stack h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-book-stack-2 h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">{{ $t('sidebar.ideaCollection') }}</span>
        </el-menu-item>
      </router-link>
      <!-- /Collection -->

      <!-- Admin Page -->
      <router-link v-if="isTemplateAdmin" class="decoration-none" :to="{name: 'admin'}">
        <el-menu-item
          class="collection item-big"
          :class="{
            'active is-active': isActive('admin')
          }"
        >
          <i class="icon ib-icon ib-icon-settings h4 mr-3" />
          <i class="icon-filled ib-icon ib-icon-settings h4 mr-3" />
          <span :class="{'collapse': isMenuCollapsed}">Admin settings</span>
        </el-menu-item>
      </router-link>
      <!-- /Admin Page -->

      <!--      AI COMING SOON-->
      <!--      <ai-coming-soon :is-menu-collapsed="isMenuCollapsed" />-->
      <!--      AI COMING SOON-->

      <!-- Coming Soon -->
      <div class="coming-soon d-none">

        <!-- Pitch Deck -->
        <el-menu-item index="4" disabled>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 3v2h1v11a2 2 0 002 2h7v3h2v-3h7a2 2 0 002-2V5h1V3H1zm3 2h16v10.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5V5zm5 2a3 3 0 100 6c1.657 0 2.969-1.359 3-3H9V7zm5 0v2h4V7h-4zm0 4v2h4v-2h-4z" fill="#9295A7" fill-rule="evenodd"/></svg>
          <span>Pitch Deck <span class="soon">Soon</span></span>
        </el-menu-item>
        <!-- /Pitch Deck -->

        <!-- Business Plan -->
        <el-menu-item index="5" disabled>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 2C5.355 2 4 3.355 4 5v15c0 1.645 1.355 3 3 3h13v-2H7c-.565 0-1-.435-1-1s.435-1 1-1h13V4H9.584C9.141 2.893 8.257 2 7 2zm0 2c.565 0 1 .435 1 1v12H7c-.352 0-.685.074-1 .188V5c0-.565.435-1 1-1zm3 2h8v11h-8V6zm2 2v2h4V8h-4zm0 4v2h4v-2h-4z" fill="#9295A7" fill-rule="evenodd"/></svg>
          <span>Business Plan <span class="soon">Soon</span></span>
        </el-menu-item>
        <!-- /Business Plan -->

        <!-- Track Results -->
        <el-menu-item index="6" disabled>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 1c-1.1 0-2 .9-2 2v14h2V3h12V1H5zm4 4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V10l-5-5H9zm0 2h7v4h4v10H9V7zm4 6l1.793 1.793L13 16.586 11.414 15 10 16.414l3 3 3.207-3.207L18 18v-5h-5z" fill="#9295A7" fill-rule="evenodd"/></svg>
          <span>Track Results <span class="soon">Soon</span></span>
        </el-menu-item>
        <!-- /Track Results -->

      </div>
      <!-- /Coming Soon -->
    </el-menu>
    <!-- /Menu -->

    <div>
      <!-- Gamification Banner -->
      <div
        v-if="(subscriptionIsFreeTrial && !isMenuCollapsed) && !isAwardActivated"
        class="gamification-banner d-md-none d-lg-block"
      >
        <gamification-banner />
      </div>
      <!-- /Gamification Banner -->

      <!-- Idea Details -->
      <main-sidebar-idea-details @click="showIdeaSettingsDialog" />
      <!-- /Idea Details -->
    </div>
  </div>
</template>

<script>
import MainSidebarIdeaDetails from '@/views/Home/Components/MainSidebar/components/MainSidebarIdeaDetails'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import UserSubscriptionMixin from '@/mixins/permissions/userSubscription'

import { mapGetters, mapMutations, mapState } from 'vuex'
import GamificationBanner from '@/views/Home/Gamification/Components/GamificationBanner.vue'

export default {
  name: 'MainSidebarMenu',

  components: {
    GamificationBanner,
    MainSidebarIdeaDetails
  },

  mixins: [
    MixinIdeaRoles,
    UserSubscriptionMixin
  ],

  data () {
    return {
      defaultActive: this.$route.path
    }
  },

  computed: {
    ...mapState({
      idea: state => state.idea
    }),

    ...mapState('ui', [
      'sidebar'
    ]),

    ...mapGetters('user', ['isTemplateAdmin']),
    ...mapGetters('theme', ['isMenuCollapsed']),
    ...mapGetters('user/gamification', ['isAwardActivated'])
  },

  watch: {
    '$route.name' (newValue, oldValue) {
      if (newValue !== oldValue) this.setSidebarState(false)
      // if (!newValue.includes('business-guide')) {
      //   this.closeMenu()
      // }
    }
  },

  // mounted () {
  //   if (this.$route.name.includes('business-guide')) {
  //     this.openMenu()
  //   }
  // },

  methods: {
    ...mapMutations('ui', [
      'setSidebarState',
      'setDialogIdeaSettingsState'
    ]),

    showIdeaSettingsDialog () {
      if (this.canEdit) {
        this.$router.push({ name: 'idea-settings' })
        // this.setDialogIdeaSettingsState(true)
      }
    },

    // openMenu () {
    //   this.$refs.menu.open('/business-guide')
    // },
    //
    // closeMenu () {
    //   this.$refs.menu.close('/business-guide')
    // },

    isActive (routeName) {
      return this.$route.name.includes(routeName)
    }
  }
}
</script>

<style lang="scss" scoped>
.collection {
  &.el-menu-item {
    border-top: 1px solid rgba(0, 0, 0, 0.14);
  }
}

.coming-soon {
  transition: all ease-in 0.25s;
  opacity: 1;
  visibility: visible;

  &.coming-soon-hidden {
    transition: all ease-in 0.25s;
    opacity: 0;
    visibility: hidden;
  }
}

.soon {
  font-family: outfit-medium;
  color: $color-yellow;
  font-size: 12px;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

#menu-sidebar {
  ::v-deep {
    .el-submenu__title {
      @extend %menu-item;
    }

    .el-menu-item-group__title {
      display: none;
    }

    .el-submenu {
      a {
        span {
         color: rgba($color-navy, 0.8);
        }
      }

      &.active {
        .el-submenu__title {
          @extend %menu-item;
          border-left: 3px solid $color-primary !important;
          background-color: #deebff !important;
          transition: all 0.25s;

          a {
            span {
              color: $color-primary;
            }
          }

          & i {
            color: inherit;
          }

          & .icon {
            display: none;
          }

          & .icon-filled {
            display: inline-block !important;
            color: inherit;
          }
        }
      }
    }
  }
}

.el-submenu__title,
.el-menu-item {
  @extend %menu-item;
}

%menu-item {
  height: 40px;
  line-height: 40px;
  font-family: outfit-regular;
  font-size: 15px;
  color: rgba($color-navy, 0.8);
  transition: all 0.25s;
  border-left: 3px solid transparent;

  span {
    color: rgba($color-navy, 0.8);

    &.collapse {
      @include media-breakpoint-up($md) {
        display: none;
      }
    }

    @include media-breakpoint-between($md, $lg) {
      display: none;
    }
  }

  &.item-big {
    height: 50px;
    line-height: 50px;
  }

  .ib-icon {
    color: #909399d1;
  }

  .icon-filled {
    display: none;
  }

  &.active {
    transition: all 0.25s;
    color: $color-primary;
    background-color: #deebff;
    border-left: 3px solid $color-primary;
      transition: all 0.25s;

    span {
      color: $color-primary !important;
    }

    .icon {
      display: none;
    }

    .icon-filled {
      display: inline-block;
      color: inherit;
    }

    &:hover {
     background-color: #deebff;
     border-left: 3px solid $color-primary;
    }
  }

  &.highlight {
    transition: all 0.25s;
    color: #ffba00;
    background-color: #deebff;
    border-left: 3px solid $color-primary;
  }

  &:hover {
    transition: all 0.25s;
    color: $color-primary;
    //background-color: #deebff;
    //border-left: 3px solid $color-primary;
    background-color: $color-white;
    border-left: 3px solid $color-white;
    span {
      color: $color-primary;
    }

    .ib-icon {
      transition: all 0.25s;
      color: $color-primary;
    }
  }

  &.is-disabled {
    opacity: 0.4;
  }

  &-group {
    &__title {
      padding: 0;
    }

    .el-menu-item {
      height: 46px;
      line-height: 46px;
    }
  }
}

.el-menu {
  border-right: none;

  &--inline {
    min-height: auto;
    box-shadow: none;
    border-right: none;
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: auto;
  transform: translateZ(0);
}

.icon-validation-custom {
  font-size: 18px;
  padding: 0 2px 0 2px;
}

.gamification-banner {
  padding: 15px
}
</style>
