<template>
  <!-- Dialog Logout -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    top="8vh"
    width="550px"
    empty-header
    @close="closeDialogLogout"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/dialogs/fogg-logged-out-1.svg')"
      :title="$t('dialogs.logout.title')"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('yes')"
        :cancel-btn-text="$t('noTakeMeBack')"
        space-between-buttons
        @cancel="closeDialogLogout"
        @submit="logout"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Logout -->
</template>

<script>
export default {
  name: 'DialogLogout',

  props: {
    visible: Boolean
  },

  methods: {
    closeDialogLogout () {
      this.$emit('close')
    },

    logout () {
      this.$cookies.remove('access_token')
      this.$http.defaults.headers.common['X-Authorization'] = null
      localStorage.removeItem('persisted_state')
      this.$intercom.shutdown()
      this.$store.dispatch('resetState')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
