<template>
  <ib-dialog
    :visible="visible"
    :closable="false"
    empty-header
    top="8vh"
    width="850px"
    @close="close"
  >
    <ib-dialog-container class="content-wrapper">
      <el-row :gutter="40">
        <el-col :md="12" class="mb-3 mb-md-0">
          <div>
            <h5 class="font-outfit-medium mt-0 mb-4">
              {{ $t('aiAssistance.comingSoon') }}
            </h5>
            <h2 class="font-outfit-medium m-0 mb-2">
              {{ $t('aiAssistance.elevateYourBusiness') }}
            </h2>
            <p>{{ $t('aiAssistance.getIntelligentSuggestions') }}</p>
            <p>{{ $t('aiAssistance.autoWrite') }}</p>
            <p>{{ $t('aiAssistance.butThatNotAll') }}</p>
            <p>{{ $t('aiAssistance.getReady') }}</p>
          </div>
        </el-col>
        <el-col :md="12">
          <img
            class="img-responsive"
            src="@/assets/img/util/ai-coming-soon.png"
            alt="coming soon"
          >
        </el-col>
      </el-row>
    </ib-dialog-container>

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('close')"
        :visible-cancel-btn="false"
        @submit="close"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'DialogAiAssistance',

  mixins: [MixinUserSubscription],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
