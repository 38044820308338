<template>
  <div
    class="gamification-banner-wrapper cursor-pointer"
    @click="onClick"
  >
    <div class="title-wrapper d-flex justify-content-between mb-2">
      <p class="m-0 font-outfit-regular">
        {{ $t('gettingStarted') }}
      </p>
      <i class="ib-icon-arrow-right" />
    </div>

    <el-progress
      :percentage="getPercentage"
      :show-text="false"
      :stroke-width="12"
      color="#42D778"
    />

    <p class="percentage m-0 mt-1 o-50 font-outfit-regular">
      {{ getPercentage }}% <span class="text-lowercase">{{ $t('complete') }}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GamificationBanner',

  computed: {
    ...mapGetters('user/gamification', ['getPercentage'])
  },

  methods: {
    onClick () {
      if (this.$router.currentRoute.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gamification-banner-wrapper {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 2px 7px 0 rgba(41, 47,  77, 0.15);
  padding: 10px 20px 15px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 2px 4px 10px 0 rgba(41, 47, 77, 0.15);

    .title-wrapper {
      p, i {
        color: $color-primary;
      }
    }
  }

  .title-wrapper {
    p, i {
      transition: color 0.3s ease;
      font-size: 15px;
    }
  }

  .percentage {
    font-size: 12px;
  }
}
</style>
