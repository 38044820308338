<template>
  <div class="d-flex">
    <div
      id="main-sidebar"
      class="d-none d-md-flex flex-column"
      :class="{'collapse': isMenuCollapsed}"
    >
      <!-- User Details -->
      <main-sidebar-header />
      <!-- /User Details -->

      <!-- Menu -->
      <main-sidebar-menu />
      <!-- /Menu -->
    </div>

    <ib-drawer
      :visible="sidebar"
      direction="ltr"
      @close="$emit('close')"
    >
      <!-- User Details -->
      <main-sidebar-header />
      <!-- /User Details -->

      <!-- Menu -->
      <main-sidebar-menu />
      <!-- /Menu -->
    </ib-drawer>
  </div>
</template>

<script>
import IbDrawer from '@/components/_v2/Drawer/IbDrawer'
import MainSidebarHeader from '@/views/Home/Components/MainSidebar/components/MainSidebarHeader'
import MainSidebarMenu from '@/views/Home/Components/MainSidebar/components/MainSidebarMenu'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MainSidebar',

  components: {
    IbDrawer,
    MainSidebarHeader,
    MainSidebarMenu
  },

  mixins: [
    MixinIdeaRoles
  ],

  computed: {
    ...mapState('ui', [
      'sidebar'
    ]),
    ...mapGetters('theme', ['isMenuCollapsed'])
  }
}
</script>

<style lang="scss" scoped>
#main-sidebar {
  width: $ib-sidebar-width;
  background-color: $color-white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14), 6px 0 6px 0 rgba(0, 0, 0, 0.06);
  border-right: 1px solid rgba(0, 0, 0, 0.14);

  &:hover {
    ::v-deep{
      .user-details{
        .main-sidebar-arrow-wrapper {
          @include media-breakpoint-up($xl) {
            transition: opacity .20s ease-in;
            opacity: 1;
          }
        }
      }
    }
  }

  @include media-breakpoint-between($md, $lg) {
    width: $ib-sidebar-width-tablet;
  }

  &.collapse {
    width: $ib-sidebar-width-tablet;
  }
}
</style>
