<template>
  <top-bar-dropdown ref="mobileTopBarDropdown">
    <top-bar-dropdown-item
      v-for="step in businessGuideNavigationsSteps"
      :key="step.name"
      :item="step"
    >
      <template v-if="step.children">
        <top-bar-dropdown-sub-item
          v-for="child in step.children"
          :key="child.name"
          :active="$route.name === child.route"
          :disabled="!child.route"
          @click="onClick(child)"
        >
          <template #icon>
            <i
              :class="child.icon"
              class="ib-icon h4 mr-2"
            />
          </template>
          {{ $t(child.name) }}
          <!--          <template v-if="subscriptionIsFreeTrial && child.paid">-->
          <!--            <i class="ib-icon ib-icon-lock-2 o-50 h5" />-->
          <!--          </template>-->
          <sup
            v-if="!child.route"
            class="text-yellow"
          >
            {{ $t('soon') }}
          </sup>
          <template #right-side>
            <div
              v-if="child.completedStatus"
              :class="{'checked': isStepCompleted(child.completedStepName)}"
              class="pseudo-checkbox position-relative"
            >
              <i
                v-if="isStepCompleted(child.completedStepName)"
                class="el-icon-check position-absolute"
              />
            </div>
          </template>
        </top-bar-dropdown-sub-item>
      </template>
    </top-bar-dropdown-item>
  </top-bar-dropdown>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import TopBarDropdown from '@/components/_v2/TopBar/TopBarDropdown'
import TopBarDropdownItem from '@/components/_v2/TopBar/TopBarDropdownItem'
import TopBarDropdownSubItem from '@/components/_v2/TopBar/TopBarDropdownSubItem'
import businessGuideNavigationsSteps from '@/data/businessGuideNavigationsSteps'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BusinessGuideMobileDropdown',

  components: {
    TopBarDropdown,
    TopBarDropdownItem,
    TopBarDropdownSubItem
  },

  mixins: [MixinUserSubscription],

  data () {
    return {
      businessGuideNavigationsSteps
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getStepIsCompleted'
    ])
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    onClick (child) {
      // if (this.subscriptionIsFreeTrial && child.paid) {
      //   this.setDialogUpgradeState(true)
      //   this.$refs.mobileTopBarDropdown.close()
      //
      //   return
      // }

      if (this.$router.currentRoute.name !== child.route) {
        this.$router.push({ name: child.route })
      }
      this.$refs.mobileTopBarDropdown.close()
    },

    isStepCompleted (completedStepName) {
      if (!completedStepName) return false

      return this.getStepIsCompleted(completedStepName)
    }
  }
}
</script>

<style scoped lang="scss">
.pseudo-checkbox {
  width: 27px;
  height: 27px;
  background-color: $color-text-very-pale-blue;
  border-radius: 50%;

  &.checked {
    background-color: #40D277;
    border-color: #40D277;
    //opacity: 0.6;

    i {
      color: $color-white;
    }
  }

  i {
    top: 25%;
    right: 25%;
  }
}

</style>
