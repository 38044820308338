<template>
  <el-menu
    class="additional-menu"
    @item-click="emitCloseEvent"
  >
    <el-menu-item @click="setDialogFeedbackState(true)">
      <i class="ib-icon ib-icon-feedback-2 h4 mr-2" />
      <span>{{ $t('topbar.feedback') }}</span>
    </el-menu-item>

    <ib-divider class="d-flex" block />

    <el-menu-item @click="onHelp">
      <i class="ib-icon ib-icon-help h4 mr-2" />
      <span>{{ $t('topbar.help') }}</span>
    </el-menu-item>

    <ib-divider class="d-flex" block />

    <el-menu-item @click="setDialogUserInviteState({visible: true})">
      <i class="ib-icon ib-icon-add-user-group-man-man h4 mr-2" />
      <span>{{ $t('pages.users.invite') }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'AccountSettingsAdditionalMenu',

  methods: {
    ...mapMutations('ui', [
      'setDialogUserInviteState',
      'setDialogFeedbackState'
    ]),

    onHelp () {
      window.open(process.env.VUE_APP_HELP_URL, '_blank')
      this.$gtm.trackEvent({
        event: 'helpButton'
      })
    },

    emitCloseEvent () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.additional-menu {
  ::v-deep {
    .el-menu-item {
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
