<template>
  <div class="account-settings-header px-2 py-3">
    <div class="d-flex align-items-center">
      <!-- User Avatar -->
      <div
        class="position-relative user-avatar"
        @click="onAvatarClick"
      >
        <ib-user-avatar :user="getCurrentUser" />
        <!--        <sub class="avatar-sub d-flex align-items-baseline position-absolute bg-white">-->
        <!--          <i class="ib-icon ib-icon-rating text-yellow" />-->
        <!--          <span>-->
        <!--            {{ ideasCount }}-->
        <!--          </span>-->
        <!--        </sub>-->
      </div>
      <!-- /User Avatar -->

      <!-- User Info -->
      <div class="user-info-wrapper flex-grow-1">
        <p class="m-0 text-white three-dots h5">
          {{ userFullName }}
        </p>
        <p class="m-0 three-dots plan-name">
          {{ subscriptionPlanName }}
        </p>
      </div>
      <!-- User Info -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AccountSettingsHeader',

  computed: {
    ...mapGetters('user', [
      'getCurrentUser',
      'userFullName',
      'ideasCount',
      'subscriptionPlanName'
    ])
  },

  methods: {
    ...mapMutations('ui', ['setAccountSettingsState']),

    onAvatarClick () {
      if (this.$router.currentRoute.name !== 'account-settings-my-profile') {
        this.$router.push({ name: 'account-settings-my-profile' })
      }
      this.setAccountSettingsState(false)
    }
  }
}
</script>

<style scoped lang="scss">
.account-settings-header {
  color: $color-white;
  background-color: $color-primary;

  .user-avatar {
    margin-right: 12px;
  }

  .avatar-sub {
    color: #000000;
    bottom: 0;
    right: -6px;
    padding: 0 6px;
    border-radius: 10px;
    font-size: 12px;

    .ib-icon {
      margin-right: 2px;
    }
  }

  .user-info-wrapper {
    white-space: nowrap;
    overflow: hidden;

    .plan-name {
      font-size: 14px;
      color: $color-white;
      opacity: 0.5;
    }
  }
}
</style>
