<template>
  <!-- Dialog ChangeBilling -->
  <ib-dialog
    v-if="$store.state.user.subscription.update_url"
    class="change-billing-dialog"
    :visible="visible"
    empty-header
    fullscreen
    @close="$emit('close')"
  >
    <!-- Dialog Body -->
    <ib-dialog-container
      max-width="100%"
      class="change-billing-container position-relative"
    >
      <iframe class="mt-4 mt-md-0" :src="$store.state.user.subscription.update_url" frameborder="0" />
    </ib-dialog-container>
    <!-- /Dialog Body -->
  </ib-dialog>
  <!-- /Dialog ChangeBilling -->
</template>

<script>
export default {
  name: 'DialogChangeBilling',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .change-billing-dialog {
    ::v-deep {
      .el-dialog__headerbtn {
        top: 10px;
        right: 10px;
        z-index: 1;
      }
    }
  }

  .change-billing-container {
    min-height: 600px;

    @include media-breakpoint-up($lg) {
      height: calc(100vh - 7rem);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
</style>
