<template>
  <li
    :class="{'disabled': disabled, 'active': active}"
    class="dropdown-item p-3"
    @click.stop="onClick"
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <slot name="icon" />
        <span class="h6">
          <slot />
        </span>
      </div>
      <div>
        <slot name="right-side" />
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'TopBarDropdownSubItem',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-item {
  color: $color-text-gray;

  &.active {
    color: $color-primary;
  }

  &.disabled {
    color: $color-text-light-gray;
  }
}
</style>
