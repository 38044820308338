<template>
  <div class="user-settings-menu ib-box-shadow" :class="{ 'active': userSettingsVisible }">
    <div class="inner-wrapper">

      <!-- Header -->
      <header>
        <p>{{ $t('settings.accountSettings') }}</p>
      </header>
      <!-- /Header -->

      <!-- Wrappers -->
      <div class="grid-wrapper">
        <el-row :gutter="40">

          <!-- My Profile -->
          <el-col :md="12">
            <div class="item" @click="goToAccountSettingsPage('my-profile')">
              <i class="ib-icon ib-icon-user text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('settings.myProfile') }}</p>
            </div>
          </el-col>
          <!-- /My Profile -->

          <!-- Plans and Upgrades -->
          <el-col :md="12">
            <div class="item" @click="$router.push({ name: 'payment' })">
              <i class="ib-icon ib-icon-double-up text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('settings.plansAndUpgrades') }}</p>
            </div>
          </el-col>
          <!-- /Plans and Upgrades -->

          <!-- Users and Members -->
          <el-col :md="12">
            <div class="item" @click="() => $router.push({ name: 'users' })">
              <i class="ib-icon ib-icon-team text-silver h2 mr-3 ib-transition" />
              <p>
                {{ $t('settings.users') }}
              </p>
            </div>
          </el-col>
          <!-- /Users and Members -->

          <!-- Idea Collection -->
          <el-col :md="12">
            <div class="item" @click="() => $router.push({ name: 'idea-collection' })">
              <i class="ib-icon ib-icon-book-stack text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('sidebar.ideaCollection') }}</p>
            </div>
          </el-col>
          <!-- /Idea Collection -->

          <!-- Payment Details -->
          <el-col :md="12">
            <div class="item" @click="$emit('payment-history')">
              <i class="ib-icon ib-icon-activity-history text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('settings.paymentHistory') }}</p>
            </div>
          </el-col>
          <!-- /Payment Details -->

          <!-- Idea Collection -->
          <el-col :md="12">
            <div class="item" @click="() => $router.push({ name: 'ai-assistance' })">
              <i class="ib-icon ib-icon-magic-2 text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('aiAssistancePage.title') }}</p>
            </div>
          </el-col>
          <!-- /Idea Collection -->

          <!-- Change billing -->
          <el-col v-if="$store.state.user.subscription.update_url" :md="12">
            <div class="item" @click="$emit('change-billing')">
              <i class="ib-icon ib-icon-currency-exchange text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('settings.changeBilling') }}</p>
            </div>
          </el-col>
          <!-- /Change billing -->

          <!-- Refer a Friend -->
          <!-- TODO - Add when referer a friend feature is finished
          <el-col :md="12">
            <div class="item">
              <svg width="23" height="20" viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 0C4.355 0 3 1.355 3 3s1.355 3 3 3 3-1.355 3-3-1.355-3-3-3zm10 0c-1.645 0-3 1.355-3 3s1.355 3 3 3 3-1.355 3-3-1.355-3-3-3zM6 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm10 0c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zM6 8c-1.476 0-2.835.233-3.92.7-.542.233-1.023.525-1.408.93-.385.407-.672.97-.672 1.587V14h16v-2h-4v-.783C12 10.864 13.401 10 16 10h5.613c-.224-.339-.566-.69-1.11-1.008C19.424 8.362 17.783 8 16 8c-2.181 0-3.951.508-4.99 1.34a4.588 4.588 0 00-1.09-.64C8.835 8.232 7.476 8 6 8zm0 2c1.255 0 2.397.222 3.129.537.366.158.622.339.748.47.125.133.123.172.123.21V12H2v-.783c0-.038-.002-.077.123-.21.126-.131.382-.312.748-.47C3.603 10.222 4.745 10 6 10zm12 2v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z" fill="none" fill-rule="evenodd"/></svg>
              <p>Refer a Friend</p>
            </div>
          </el-col>
          -->
          <!-- /Refer a Friend -->

          <!-- Log Out -->
          <el-col :md="12">
            <div class="item" @click="$emit('logout')">
              <i class="ib-icon ib-icon-export text-silver h2 mr-3 ib-transition" />
              <p>{{ $t('settings.logOut') }}</p>
            </div>
          </el-col>
          <!-- /Log Out -->

        </el-row>
      </div>
      <!-- /Wrappers -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettings',
  props: {
    userSettingsVisible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goToAccountSettingsPage (page) {
      const name = 'account-settings-' + page
      this.$route.name !== name && this.$router.push({ name: name })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-settings-menu {
  opacity: 0;
  width: 100%;
  max-width: 500px;
  min-width: 480px;
  height: auto;
  position: absolute;
  transform: scale(0);
  transform-origin: 0 0;
  left: 234px;
  top: 0;
  background-color: $color-white;
  border-radius: 4px;
  transition: all 0.3s;
  z-index: 2001;

  &.active {
    left: 234px;
    transform: scale(1);
    opacity: 1;
    transition: all 0.2s;

    &.collapse {
      left: 87px;
      top: 55px;
    }

    @include media-breakpoint-between($md, $lg) {
      left: 87px;
      top: 63px;
    }
  }
}

header {
  padding-top: 18px;
  padding-bottom: 16px;
  padding-left: 20px;
  text-align: left;
  background-color: $color-primary;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-left: 0;
    border-width: 15px;
    border-style: solid;
    border-color: transparent #0e64e6 transparent transparent;
  }

  p {
    color: $color-white;
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.grid-wrapper {
  padding: 11px 25px;

  .item {
    display: -webkit-box;
    align-items: center;
    transition: all 0.25s;

    &:hover {
      transition: all 0.25s;
      cursor: pointer;

      p {
        transition: all 0.25s;
        opacity: 1;
        color: $color-royal-blue;
      }
    }

    p {
      transition: all 0.25s;
      font-family: outfit-regular;
      font-size: 16px;
      line-height: 3.75;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.6;
    }
  }
}

.item {
  &:hover {
    .ib-icon {
      color: $color-royal-blue;
    }
  }
}

</style>
