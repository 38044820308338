<template>
  <transition-group tag="div" name="fade">
    <div
      v-show="open"
      key="dropdown"
      class="top-bar-dropdown bg-white"
    >
      <slot />
    </div>
    <div
      v-show="open"
      id="backdrop-dropdown"
      key="backdrop"
    />
  </transition-group>
</template>

<script>

export default {
  name: 'TopBarDropdown',

  data () {
    return {
      open: false
    }
  },

  destroyed () {
    this.removeBackdrop()
  },

  methods: {
    toggle () {
      if (!this.open) {
        this.onOpen()
      } else {
        this.onClose()
      }
      this.open = !this.open
    },

    close () {
      if (this.open) {
        this.open = false
        this.onClose()
      }
    },

    onOpen () {
      const body = document.getElementsByTagName('BODY')[0]
      body.classList.add('overflow-hidden')

      const backdrop = document.getElementById('backdrop-dropdown')
      backdrop.classList.add('appended-backdrop')
      document.body.appendChild(backdrop)

      this.emitOpenEvent()
    },

    onClose () {
      const body = document.getElementsByTagName('BODY')[0]
      body.classList.remove('overflow-hidden')
      this.emitCloseEvent()
    },

    removeBackdrop () {
      const elements = document.getElementsByClassName('appended-backdrop')
      if (elements.length) {
        elements[0].remove()
      }
    },

    emitOpenEvent () {
      this.$emit('open')
    },

    emitCloseEvent () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.top-bar-dropdown {
  position: absolute;
  overflow: hidden auto;
  max-height: 450px;
  margin: 1px 6px auto;
  top: 100%;
  right: 0;
  left: 0;
}

#backdrop-dropdown {
  z-index:1999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 45%);
}
</style>
