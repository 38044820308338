<template>
  <el-menu
    class="account-settings-navigation"
    :default-active="$route.path"
    @item-click="emitCloseEvent"
  >
    <el-menu-item
      index="/account-settings/my-profile"
      @click="navigate"
    >
      <i class="ib-icon ib-icon-user h4 mr-2" />
      <span>{{ $t('settings.myProfile') }}</span>
    </el-menu-item>

    <el-menu-item
      index="/payment"
      @click="navigate"
    >
      <i class="ib-icon ib-icon-double-up h4 mr-2" />
      <span>{{ $t('settings.plansAndUpgrades') }}</span>
    </el-menu-item>

    <el-menu-item
      index="/users"
      @click="navigate"
    >
      <i class="ib-icon ib-icon-team h4 mr-2" />
      <span>{{ $t('settings.users') }}</span>
    </el-menu-item>

    <el-menu-item
      index="/idea-collection"
      @click="navigate"
    >
      <i class="ib-icon ib-icon-book-stack h4 mr-2" />
      <span>{{ $t('sidebar.ideaCollection') }}</span>
    </el-menu-item>

    <el-menu-item @click="setDialogPaymentHistoryState(true)">
      <i class="ib-icon ib-icon-activity-history h4 mr-2" />
      <span>{{ $t('settings.paymentHistory') }}</span>
    </el-menu-item>

    <el-menu-item
      index="/ai-assistance"
      @click="navigate"
    >
      <i class="ib-icon ib-icon-magic-2 h4 mr-2" />
      <span>{{ $t('aiAssistancePage.title') }}</span>
    </el-menu-item>

    <el-menu-item
      v-if="$store.state.user.subscription.update_url"
      @click="setDialogChangeBillingState(true)"
    >
      <i class="ib-icon ib-icon-currency-exchange h4 mr-2" />
      <span>{{ $t('settings.changeBilling') }}</span>
    </el-menu-item>

    <el-menu-item @click="setDialogLogoutState(true)">
      <i class="ib-icon ib-icon-export h4 mr-2" />
      <span>{{ $t('settings.logOut') }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'AccountSettingsMenu',

  methods: {
    ...mapMutations('ui', [
      'setDialogPaymentHistoryState',
      'setDialogLogoutState',
      'setDialogChangeBillingState'
    ]),

    navigate (event) {
      if (this.$route.path !== event.index) {
        this.$router.push(event.index)
      }
    },

    emitCloseEvent () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.account-settings-navigation {
  ::v-deep {
    .el-menu-item {
      font-family: $outfit-regular;
      color: rgba(41, 47, 77, 0.8);
      span {
        font-size: 15px;
      }
      //&.is-active {
      //  color: $color-royal-blue;
      //  background-color: #deebff;
      //}
    }
  }
}
</style>
