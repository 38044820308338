<template>
  <div
    class="dropdown-item-wrapper"
    @click="$emit('click')"
  >
    <div
      class="item p-3 h4"
      :class="{'active': isActive}"
    >
      <slot name="icon" /> {{ $t(item.name) }}
    </div>
    <ib-divider block />
    <ul
      v-if="item.children"
      class="item-list"
    >
      <slot />
    </ul>
    <ib-divider v-if="item.children" block />
  </div>
</template>

<script>
export default {
  name: 'TopBarDropdownItem',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    isActive () {
      return this.$route.name === this.item.route && this.item.children === null
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-item-wrapper {
  .item {
    color: $color-navy;

    &.active {
      color: $color-primary;
    }
  }

  .item-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
</style>
