<script>
// This is Renderless component service provider
export default {
  name: 'FreeTrialServiceProvider',

  data () {
    return {
      freeTrial: null,
      daysToExpire: null
    }
  },

  computed: {
    isDangerBtn () {
      return this.daysToExpire <= 3
    }
  },

  watch: {
    '$store.state.user.freeTrial': {
      handler () {
        this.freeTrialCheck()
      },
      immediate: true
    }
  },

  mounted () {
    this.freeTrialCheck()
    const trial = setInterval(() => !this.freeTrialCheck() || clearInterval(trial), 60000)
  },

  methods: {
    freeTrialCheck () {
      this.freeTrial = null
      this.daysToExpire = null

      const now = this.$moment.utc(Date.now())
      const endDate = this.$moment.utc(this.$store.state.user.freeTrial)

      if (this.$store.state.user.freeTrial && endDate > now) {
        this.freeTrial = endDate.from(now)
        this.daysToExpire = endDate.diff(now, 'days')
      }

      return this.freeTrial
    },

    goToPricingPage () {
      this.$gtm.trackEvent({
        event: 'freeTrialButton'
      })

      this.$router.push({ name: 'payment' })
    }
  },

  render () {
    return this.$scopedSlots.default({
      freeTrial: this.freeTrial,
      isDangerBtn: this.isDangerBtn,
      goToPricingPage: this.goToPricingPage
    })
  }
}
</script>
