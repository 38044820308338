<template>
  <div
    class="main-sidebar-arrow-wrapper cursor-pointer"
    :class="{'collapsed': isMenuCollapsed}"
    @click="setMenuCollapsedToggle"
  >
    <i :class="arrowIcon" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MainSidebarArrow',

  computed: {
    ...mapGetters('theme', ['isMenuCollapsed']),

    arrowIcon () {
      return this.isMenuCollapsed ? 'ib-icon-forward' : 'ib-icon-back'
    }
  },

  methods: {
    ...mapMutations('theme', ['setMenuCollapsedToggle'])
  }
}
</script>

<style scoped lang="scss">
.main-sidebar-arrow-wrapper {
  opacity: 0;
  transition: opacity .20s ease-out;
  background-color: $color-white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14), 6px 0 6px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.14);
  position: absolute;
  bottom: -5%;
  right: -5%;
  z-index: 1;

  &.collapsed {
    bottom: -12%;
    right: -15%;
  }

  i {
    font-size: 18px;
  }
}
</style>
